/**
 * Emit a new value on the channel
 */
export type BehaviorSubjectDispatcher<T> = (value: T) => void;

/**
 * Add a listener, be provided with a unsubscribe callback
 * (duplicates not detected)
 */
export type BehaviorSubjectSubscribe<T> = (
  listener: BehaviorSubjectListener<T>
) => () => void;

export type BehaviorSubjectListener<T> = (value: T) => void;

/**
 * Behavior Subject Tuple:
 * [0] to dispatch a new value
 * [1] to add a listener
 *
 * Behavior Subject Semantics:
 * * Always has a value
 * * Immediately receives last value on subscribe
 */
export type BehaviorSubject<T> = [
  BehaviorSubjectDispatcher<T>,
  BehaviorSubjectSubscribe<T>
];

/**
 * Very lightweight behavior subject
 * @param current The initial value for the Behavior Subject
 * @param listeners Optionally initialize to an array of listeners
 */
export const createBehaviorSubject = <T>(
  current: T,
  listeners: Array<BehaviorSubjectListener<T>> = []
): BehaviorSubject<T> => [
  /* dispatch */
  (value) => {
    current = value;
    listeners.forEach((listener) => listener(value));
  },

  /* subscribe */
  (listener) => {
    listener(current);
    listeners.push(listener);
    return () => {
      listeners = listeners.filter((l) => l !== listener);
    };
  }
];
